//# Debounce Function
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

/**
 * * Email input additional validation
 * OK Login
 * OK Forgot Password
 * 
 * # Maximum length 50
 * # Input type email if not administrator
 */
const getterEmail = $("#login_email, #forgot_email")
getterEmail.attr("maxlength", 50);
getterEmail.on('input', debounce((e) => {
    //# Get Target Value
    targetValue = e.target.value

    if(getterEmail.val() == 'Administrator') {
        getterEmail.attr("type", "text")
    } else {
        getterEmail.attr("type", "email")
    }
}, 500))


// * Send Email button in Forgot Password
$(".btn-forgot").html("Send Email");

// * Call API login for the second time to process alert
$.extend(frappe, {
    call: function (opts) {
		if (typeof arguments[0] === "string") {
			opts = {
				method: arguments[0],
				args: arguments[1],
				callback: arguments[2],
			};
		}

		frappe.prepare_call(opts);
		if (opts.freeze) {
			frappe.freeze();
		}
		return $.ajax({
			type: opts.type || "POST",
			url: opts.url || "/",
			data: opts.args,
			dataType: "json",
			headers: {
				"X-Frappe-CSRF-Token": frappe.csrf_token,
				"X-Frappe-CMD": (opts.args && opts.args.cmd) || "" || "",
			},
			statusCode: opts.statusCode
		}).always(function (data) {
			if (opts.freeze) {
				frappe.unfreeze();
			}

            // executed before statusCode functions
            let response = (data?.responseText) || (data?._server_messages ? (JSON.parse(data?._server_messages)[0]) : "")
            let { title="Error", message: responseMessage } = response ? JSON.parse(response) : ''
            let indicator = "red"
            let message
            const is_success_login = Boolean(data?.full_name) && opts?.url === "/login"
            const is_update_password = opts?.method === "frappe.core.doctype.user.user.test_password_strength"

            let httpStatus = data?.status
            if (httpStatus) {
                // * Handling response with httpStatus dialog
                switch (httpStatus) {
                    case 401:
                        // Wrong password
                        message = "Invalid Login. Try again."
                        break;

                    case 404:
                        message = "User does not exist."
                        break;

                    case 417:
                        // Invalid password strength check
                        // Disabled user requesting set password
                        const resp = JSON.parse(response)
                        const resp_server_messages = JSON.parse(JSON.parse(resp?._server_messages)[0])
                        message = resp_server_messages ? resp_server_messages.message : responseMessage
                        break;

                    case 500:
                        message = "Something went wrong."
                        break;
                }
            } else {
                // * Handling response without httpStatus dialog

                if (data._server_messages) {
                    // * Enabled user requesting reset password
                    title = title
                    message = responseMessage
                    indicator = "green"
                } else if (data.message) {
                    // * Disabled user requesting reset password
                    message = "Please contact admin to do this action."
                    indicator = "red"
                }
            }

            // * Show alert
            if (!is_success_login && !is_update_password) {
                frappe.msgprint({
                    title: __(title),
                    indicator: indicator,
                    message: __(message)
                });
            }
		});
	}
})

// * Force redirect to workspace after login
$(function () {
    if (location.pathname == "/login"){
        const url = new URL(window.location);
        url.searchParams.set('redirect-to', '/app');
        window.history.pushState({}, '', url);
    }
});
